<template>
  <div class="tw-flex tw-justify-center">
    <div class="page-sculptor">
      <div class="tw-flex mb-5">
        <v-btn class="add-pd" @click="$router.push({name:'AllPaddlers'})">
          <icons name="back" class="mr-2" />
          All Paddlers</v-btn>
        <v-spacer></v-spacer>
        <v-btn class="darker-text" style="letter-spacing:0" text @click="routeToEdit">
          <icons name="update-paddler" class="mr-2" />
          Update Paddler
        </v-btn>
      </div>
      <div class="white-bg">
        <v-col sm="7" cols="12">
          <div class="image-border">
            <img class="existing-img" :src="selectedPaddler.profileImage" />
          </div>
        </v-col>
        <v-col sm="5" cols="12" class="tw-flex tw-justify-center tw-flex-col">
          <p class="add-pd">{{selectedPaddler.firstname + " " + selectedPaddler.lastname}}</p>
          <span class="darker-text my-3"> {{selectedPaddler.jobRole || '-' }}</span>
          <v-chip color="#00C48C69">{{selectedPaddler.status}}</v-chip>
        </v-col>
      </div>
      <div class="my-4"></div>
      <div class="white-bg">
        <v-col sm="4" cols="6" class="tw-flex tw-flex-col mb-4">
          <span class="lighter-text">
            First Name
          </span>
          <span class="darker-text mt-3">
            {{selectedPaddler.firstname || 'N/A' }}
          </span>
        </v-col>
        <v-col sm="4" cols="6" class="tw-flex tw-flex-col mb-4">
          <span class="lighter-text">
            Last Name
          </span>
          <span class="darker-text mt-3">
            {{selectedPaddler.lastname || 'N/A' }}
          </span>
        </v-col>
        <v-col sm="4" cols="6" class="tw-flex tw-flex-col mb-4">
          <span class="lighter-text">
            Gender
          </span>
          <span class="darker-text mt-3">
            {{selectedPaddler.gender || 'N/A' }}
          </span>
        </v-col>
        <v-col sm="4" cols="6" class="tw-flex tw-flex-col mb-4">
          <span class="lighter-text">
            Marital status
          </span>
          <span class="darker-text mt-3">
            {{selectedPaddler.maritalStatus || 'N/A' }}
          </span>
        </v-col>
        <v-col sm="4" cols="6" class="tw-flex tw-flex-col mb-4">
          <span class="lighter-text">
            NIN number
          </span>
          <span class="darker-text mt-3">
            {{selectedPaddler.nationalIdentificationNumber || 'N/A' }}
          </span>
        </v-col>
        <v-col sm="4" cols="6" class="tw-flex tw-flex-col mb-4">
          <span class="lighter-text">
            D.O.B
          </span>
          <span class="darker-text mt-3">
            {{selectedPaddler.dateOfBirth || 'N/A' }}
          </span>
        </v-col>
        <v-col sm="4" cols="6" class="tw-flex tw-flex-col mb-4">
          <span class="lighter-text">
            Unit
          </span>
          <span class="darker-text mt-3">
            {{selectedPaddler.departmentAssigned.unitName}}
          </span>
        </v-col>
        <v-col sm="4" cols="6" class="tw-flex tw-flex-col mb-4">
          <span class="lighter-text">
            Department
          </span>
          <span class="darker-text mt-3">
            {{selectedPaddler.departmentAssigned.name || 'N/A' }}
          </span>
        </v-col>
        <v-col sm="4" cols="6" class="tw-flex tw-flex-col mb-4">
          <span class="lighter-text">
            Grade level
          </span>
          <span class="darker-text mt-3">
            {{selectedPaddler.employeeLevel || 'N/A' }}
          </span>
        </v-col>
        <v-col sm="4" cols="6" class="tw-flex tw-flex-col mb-4">
          <span class="lighter-text">
            Employment Date
          </span>
          <span class="darker-text mt-3">
            {{selectedPaddler.dateOfEmployment || 'N/A' }}
          </span>
        </v-col>
        <v-col sm="4" cols="6" class="tw-flex tw-flex-col mb-4">
          <span class="lighter-text">
            Email
          </span>
          <span class="darker-text mt-3" style="text-transform:lowercase !important">
            {{selectedPaddler.email || 'N/A' }}
          </span>
        </v-col>
        <v-col sm="4" cols="6" class="tw-flex tw-flex-col mb-4">
          <span class="lighter-text">
            Staff ID
          </span>
          <span class="darker-text mt-3">
            {{selectedPaddler.staffId || 'N/A' }}
          </span>
        </v-col>
      </div>
      <div class="my-4"></div>
      <div class="white-bg">
        <v-col sm="4" cols="6" class="tw-flex tw-flex-col mb-4">
          <span class="lighter-text">
            Country of Residence
          </span>
          <span class="darker-text mt-3">
            {{selectedPaddler.addressOfEmployee.country || 'N/A' }}
          </span>
        </v-col>
        <v-col sm="4" cols="6" class="tw-flex tw-flex-col mb-4">
          <span class="lighter-text">
            State of Residence
          </span>
          <span class="darker-text mt-3">
            {{selectedPaddler.addressOfEmployee.state || 'N/A' }}
          </span>
        </v-col>
        <v-col sm="4" cols="6" class="tw-flex tw-flex-col mb-4">
          <span class="lighter-text">
            City of Residence
          </span>
          <span class="darker-text mt-3">
            {{selectedPaddler.addressOfEmployee.city || 'N/A' }}
          </span>
        </v-col>
        <v-col sm="4" cols="6" class="tw-flex tw-flex-col mb-4">
          <span class="lighter-text">
            Street name
          </span>
          <span class="darker-text mt-3">
            {{selectedPaddler.addressOfEmployee.streetName || 'N/A' }}
          </span>
        </v-col>
        <v-col sm="4" cols="6" class="tw-flex tw-flex-col mb-4">
          <span class="lighter-text">
            House Number
          </span>
          <span class="darker-text mt-3">
            {{selectedPaddler.addressOfEmployee.houseNumber || 'N/A' }}
          </span>
        </v-col>
      </div>
      <div class="my-4"></div>
      <div class="white-bg">
        <v-col sm="4" cols="6" class="tw-flex tw-flex-col mb-4">
          <span class="lighter-text">
            Weight
          </span>
          <span class="darker-text mt-3">
            {{selectedPaddler.weight || 'N/A' }}
          </span>
        </v-col>
        <v-col sm="4" cols="6" class="tw-flex tw-flex-col mb-4">
          <span class="lighter-text">
            Height
          </span>
          <span class="darker-text mt-3">
            {{selectedPaddler.height || 'N/A' }}
          </span>
        </v-col>
        <v-col sm="4" cols="6" class="tw-flex tw-flex-col mb-4">
          <span class="lighter-text">
            Blood Group
          </span>
          <span class="darker-text mt-3">
            {{selectedPaddler.bloodGroup || 'N/A' }}
          </span>
        </v-col>
        <v-col sm="4" cols="6" class="tw-flex tw-flex-col mb-4">
          <span class="lighter-text">
            Blood Type
          </span>
          <span class="darker-text mt-3">
            {{selectedPaddler.bloodType || 'N/A' }}
          </span>
        </v-col>
        <v-col sm="4" cols="6" class="tw-flex tw-flex-col mb-4">
          <span class="lighter-text">
            Allergies
          </span>
          <span class="darker-text mt-3">
            {{selectedPaddler.allergies || 'N/A' }}
          </span>
        </v-col>
        <v-col sm="4" cols="6" class="tw-flex tw-flex-col mb-4">
          <span class="lighter-text">
            Special Need?
          </span>
          <span class="darker-text mt-3">
            {{selectedPaddler.specialNeeds || 'N/A' }}
          </span>
        </v-col>
        <v-col sm="4" cols="6" class="tw-flex tw-flex-col mb-4">
          <span class="lighter-text">
            Preferred HMO Hospital
          </span>
          <span class="darker-text mt-3">
            {{selectedPaddler.preferredHMOHospital || 'N/A' }}
          </span>
        </v-col>
        <v-col sm="4" cols="6" class="tw-flex tw-flex-col mb-4">
          <span class="lighter-text">
            Hospital Location
          </span>
          <span class="darker-text mt-3">
            {{selectedPaddler.hospitalLocation || 'N/A' }}
          </span>
        </v-col>
      </div>
      <div class="my-4"></div>
      <div class="white-bg">
        <v-col sm="4" cols="6" class="tw-flex tw-flex-col mb-4">
          <span class="lighter-text">
            Salary
          </span>
          <span class="darker-text mt-3">
            ₦{{ selectedPaddler.salary  || 'N/A' }}
          </span>
        </v-col>
        <v-col sm="4" cols="6" class="tw-flex tw-flex-col mb-4">
          <span class="lighter-text">
            Bank name
          </span>
          <span class="darker-text mt-3">
            {{ selectedPaddler.accountDetail.bankName  || 'N/A' }}
          </span>
        </v-col>
        <v-col sm="4" cols="6" class="tw-flex tw-flex-col mb-4">
          <span class="lighter-text">
            Account name
          </span>
          <span class="darker-text mt-3">
            {{ selectedPaddler.accountDetail.accountName  || 'N/A' }}
          </span>
        </v-col>
        <v-col sm="4" cols="6" class="tw-flex tw-flex-col mb-4">
          <span class="lighter-text">
            Account Number
          </span>
          <span class="darker-text mt-3">
            {{ selectedPaddler.accountDetail.accountNumber  || 'N/A' }}
          </span>
        </v-col>
      </div>
      <div class="my-4"></div>
    </div>
  </div>
</template>

<script>
  import {
    mapGetters
  } from 'vuex';
  import Icons from '../../components/reuseables/Icons.vue';
  export default {
    components: {
      Icons

    },
    name: "ViewPaddler",
    data() {
      return {
        
      };
    },
    props: {},
    methods: {
      routeToEdit() {
        let selected = this.selectedPaddler
        this.$store.dispatch("paddler/setUpdateMode", true);
        this.$store.dispatch("paddler/setPaddlerData", selected)
        this.$router.push({name: 'EditPaddler',params:{staffId: selected.staffId}})
      },
    },
    computed: {
      ...mapGetters('paddler', ['selectedPaddler'])
    },
  };
</script>

<style lang="scss" scoped>
  .add-pd {
    color: #263238;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: none;
    letter-spacing: 0;
    margin-bottom: 15px;

    @media (max-width:768px) {
      justify-content: start;
      font-size: 17px;
    }
  }

  .lighter-text,
  .darker-text {
    color: #939393;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    text-transform: capitalize !important;
    line-height: 20px;
    word-wrap: break-word;

    @media (max-width:576px) {
      font-size: 12px;
    }
  }

  .darker-text {
    color: #263238;
  }

  .white-bg {
    background-color: #fff;
    padding: 30px;
    height: fit-content;
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;

    @media (max-width:768px) {
      padding: 15px;
    }
  }

  .image-border {
    border-radius: 10px;
    border: 2px dashed rgba(0, 74, 173, 0.42);
  }

  .existing-img {
    width: 100%;
    object-fit: cover;
    height: 200px;
    object-position: 100% 10%;

    @media screen and (max-width:768px) {}
  }

  .page-sculptor {
    display: flex;
    flex-direction: column;
    width: 650px;

    @media (max-width:768px) {
      width: 100%;
    }
  }

  .each-dc {
    border-radius: 10px;
    border: 2px solid rgba(189, 189, 189, 0.12);
    background: #FEFFFF;
    height: 40px;
    margin: 10px;

  }

  .v-chip.v-size--default {
    width: 80px;
    height: 27px;
    display: flex;
    justify-content: center;
    font-size: 12px !important;
    text-transform: lowercase;
  }

  .text-headings {
    color: #4F4F4F;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
</style>